<template>
    <div class="container">
        <p class="tagline">The world is your oyster!</p>
        <p class="thanks">Thank you for choosing <strong>Jumpseat Media</strong> for your audio touring pleasure!</p>
        
        <p class="thanks2">The funds we collect help cover the cost of services we, in turn, subscribe to, in order to provide you
            with a best-in-class experience. They also help keep Jumpseat Radio a free community benefit. For that,
            and from the entire Jumpseat community, we <strong>thank you for purchasing a pass</strong>! Enjoy your trip!</p>
    </div>
</template>

<script>
export default {
    name: "SideLine",
    setup() {

    }

}
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    flex-flow: column;
    align-items: stretch;
    align-content: center;
    justify-content: flex-start;
    height: 100vh;
    background-image: 
        linear-gradient(to bottom, rgba(41,128, 185, 0.95), rgba(41,128, 185, 0.1)),
        url("../../assets/pexels-valentin-antonucci-1275393.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #2980b9;

    .tagline {
        color: #FFFFFF;
        font-size: 36px;
        text-transform: uppercase;
        font-weight: bold;
        text-shadow: 0px 0px 3px #000000;
        padding-top: 24px;
    }

    .thanks {
        color: #FFFFFF;
        font-size: 22px;
        text-shadow: 0px 0px 3px #000000;
    }

    .thanks2 {
        color: #FFFFFF;
        font-size: 14px;
        text-shadow: 0px 0px 3px #000000;
        line-height: 1.5;
        margin-top: auto;
    }

}
</style>