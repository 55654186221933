import axios from "axios";

async function setSubscription(productId, userId, orderId, paymentId) {

    axios.defaults.headers['Content-Type'] = 'application/vnd.api+json';

    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + "/products",
        headers: {
            'Content-Type': 'application/json',
            post: {
                'Content-Type': 'application/json',
            },
        }
    });

    const data = {
        productId: productId,
        userId: userId,
        orderId: orderId,
        paymentId: paymentId,
    }

    await instance
        .post("setSubscription", data)
        .then(() => {  
            return;
        })
        .catch((error) => {
            console.log(error.message);
        })
    
    return;
}
    

export default setSubscription;