import axios from "axios";
import store from "../../../store";

async function getSubscriptionProducts() {

    // Use current token, or refresh if necessary
  const instance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION + "/products/getDiscoverSubscriptions"
  })
    
  const result = await instance
    .get()
    .then((response) => { 
      const final = response.data.filter((item) => {
        return item.isActive == true;
      })
      return final;
    })
    .catch((error) => {
      console.log(error.message);
    })
  
  store.dispatch("setProductLibrary", result);
  
  return;
}
    

export default getSubscriptionProducts;