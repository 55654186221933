import { createStore } from 'vuex'

export default createStore({
  state: {
    productId: null,
    userId: null,
    productLibrary: {}
  },
  mutations: {
    SET_PRODUCTID(state, productId)  {
      state.productId = productId;
    },
    SET_USERID(state, userId) {
      state.userId = userId;
    },
    SET_PRODUCTLIBRARY(state, productLibrary) {
      state.productLibrary = productLibrary;
    }
  },
  actions: {
    setProductId({ commit }, productId) {
      commit("SET_PRODUCTID", productId);
    },
    setUserId({ commit }, userId) {
      commit("SET_USERID", userId);
    },
    setProductLibrary({ commit }, productLibrary) {
      commit("SET_PRODUCTLIBRARY", productLibrary);
    }
  },
  modules: {
  }
})
