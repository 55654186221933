<template>
    <div class="payment-box">
        <transition-group
                name="swap"
                mode="in-out"
                enter-active-class="animate__animated animate__fadeIn animate__faster">
            <div v-if="!state.isPaid">
                <div v-if="state.paymentError" class="processing" key="processing">
                    <div><i class="material-icons">error_outline</i></div>
                    There was a problem completing your order. Try again.
                </div>
                <div v-if="!state.isPaid" class="payment" :class="paymentClass" ref="paypal" key="payment"></div>
            </div>
            <div v-else class="paid" key="paid">
                <div class="successful">
                        <div class="animate__animated animate__heartBeat animate__slow"><i class="material-icons">check_circle</i></div>
                        <p><strong>Payment Successful!</strong></p>
                    </div>
                    <div class="instructions">Your order reference is: &nbsp;<strong>{{ state.paymentConfirmation }}</strong>.</div>
                    <div class="instructions">You can now return to Jumpseat Media Player & enjoy your pass.</div>
            </div>
        </transition-group>
        <transition name="fadein"
                    enter-active-class="animate__animated animate__fadeIn animate__faster">
            <div v-if="state.isProcessing" class="processing">
                <div class="animate__animated animate__flip animate__infinite"><i class="material-icons">monetization_on</i></div>
                Processing...
            </div>
        </transition>
    </div>
    

</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import setSubscription from '../paypal-gateway/helpers/setSubscription';
import { useStore } from 'vuex';
export default {
    name: "PayPal",
    setup() {
        const state = reactive({
            isPaid: false,
            isProcessing: false,
            paymentError: false,
            product: {},
            paymentConfirmation: null,
            order: {}
        });

        const store = useStore();
        const productId = computed(() => store.state.productId);
        const userId = computed(() => store.state.userId);
        const products = computed(() => store.state.productLibrary);
        
        watch(products, () => {
            if(products.value) {
                const product = JSON.parse(
                    JSON.stringify(
                        products.value.filter((item) => {
                            return item.productId == productId.value;
                        })
                    )
                );
                
                state.product = product[0];
            }
        });

        const paymentClass = computed(() => state.isPaid? 'hidden' : state.isProcessing? 'hidden' : '');
        const paypal = ref('');

        function setLoaded() {
            window.paypal.Buttons({
                style: {
                    layout: 'vertical'
                },
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                description: state.product.description,
                                amount: {
                                    currency_code: "USD",
                                    value: state.product.price
                                }
                            }
                        ]
                    });
                },
                onClick: function() {
                    
                },
                onApprove: async (data, actions) => {
                    state.isProcessing = true;
                    const order = await actions.order.capture();
                    // console.log(order);
                    state.isProcessing = false;

                    if (order.status != "COMPLETED") {
                        state.paymentError = true;
                    } else {
                        state.paymentConfirmation = order.id;
                        state.order = order;

                        await setSubscription(productId.value, userId.value, order.id, order.purchase_units[0].payments.captures[0].id);

                        state.isPaid = true;
                    }

                    
                },
                onCancel: function() {
                    state.isProcessing = false;
                },
                onError: err => {
                    console.log(err);
                }
            })
            .render(paypal.value);
        }

        onMounted(() => {
            const script = document.createElement("script");
            script.src = "https://www.paypal.com/sdk/js?client-id=AeCTjcIh6K_hsAE0HMnR2ttljrp8mLCOacuZUxvhUFOgSJkUjjgV6GbwR_Tm5-EVTTpqcaIj7rim80bl&disable-funding=credit,bancontact&enable-funding=venmo";
            script.addEventListener("load", setLoaded);
            document.body.appendChild(script);
        });

        return {
            paypal,
            state,
            paymentClass
        }
    }
}
</script>

<style lang="scss" scoped>
.payment-box {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: all 0.2s ease;
}

.payment {
    display: flex;
    padding-top: 44px;
    flex-flow: row wrap;
    width: 80%;
    max-height: 65vh;
    margin: auto;
    overflow: scroll;
    transition: all 0.2s ease;

}

.processing {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #FFF8E1;
    

    .material-icons {
        font-size: 36px;
        padding: 24px;
    }
}

.paid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: #DCEDC8;
    transition: all 0.2s ease;
    

    .material-icons {
        font-size: 36px;
        padding: 24px;
    }

    .successful {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .instructions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 24px;
        font-size: 14px;
    }
}

::-webkit-scrollbar {
    width: 5px;
    height: 20px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: #ecf0f1;
    
}

.hidden {
    display: none;
}
</style>