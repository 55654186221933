<template>
  <router-view />
</template>

<script>
import { watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import getSubscriptionProducts from "./components/product-description/helpers/getSubscriptionProducts";
export default {
  name: "App",
  setup() {
    const route = useRoute();
    const router = useRouter();  
    const store = useStore();



    watchEffect(async () => {
      if(route.query.userId && route.query.productId) {
        store.dispatch("setProductId", route.query.productId);
        store.dispatch("setUserId", route.query.userId);
        getSubscriptionProducts();
        router.push({ name: 'Home' });
      }


    })

    


  }

}
</script>

<style lang="scss">
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
