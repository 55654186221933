<template>
    <div class="container">
        <div class="product">
            <p>What you're buying:</p>
            <div class="product-box">
                <div class="title">
                    {{ state.product.label }}
                </div>
                <div class="description">
                    {{ state.product.description }}
                </div>
            </div>
        <div class="charge">
            <div>TOTAL:</div>
            <div class="price">$ {{ state.product.price }}</div>
        </div>
        </div>
        
    </div>
</template>

<script>
import { computed, reactive, watch } from 'vue';
import { useStore } from 'vuex';
// import getSubscriptionProducts from './helpers/getSubscriptionProducts';
export default {
    name: "ProductDescription",
    setup() {
        const state = reactive({
            product: {}
        })
        
        const store = useStore();
        const productId = computed(() => store.state.productId);
        const products = computed(() => store.state.productLibrary);
        
        watch(products, () => {
            if(products.value) {
                const product = JSON.parse(
                    JSON.stringify(
                        products.value.filter((item) => {
                            return item.productId == productId.value;
                        })
                    )
                );
                
                state.product = product[0];
            }
        });

        return {
            state
        }

    }

}
</script>
<style lang="scss" scoped>
.container {

    .welcome {
        text-align: right;
    }

    .product {
        p {
            text-align: left;
        }

        .product-box {
            border-radius: 5px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
            display: grid;
            grid-template-columns: 1fr 5fr;
            align-items: center;

            .title {
                font-weight: bold;
                background-color: #e67e22;
                padding: 15px;
                border-bottom-left-radius: 5px;
                border-top-left-radius: 5px;
                color: #FFFFFF;
                font-size: 24px;
            }

            .description {
                line-height: 1.5;
            }
        }
    
    .charge {
        padding: 32px;
        display: flex;
        flex-direction: row;        
        font-size: 24px;
        justify-content: space-evenly;

        .price {
            font-weight: bold;
        }
    }

    }
    

}
</style>