<template>
  <div class="content">
      <SideLine/>
      <div class="product">
        <ProductDescription/>
        <PayPal/>
      </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex'
import ProductDescription from '../components/product-description/ProductDescription';
import SideLine from '../components/product-description/SideLine';
import PayPal from '../components/paypal-gateway/PayPal';
export default {
  name: 'Home',
  components: { SideLine, ProductDescription, PayPal },
  setup() {
    const store = useStore();

    const userId = computed(() => store.state.userId);
    const productId = computed(() => store.state.productId);

    return {
      userId,
      productId
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: grid;
  grid-template-columns: 3fr 2fr;

  .product {
    background-color: #FFFFFF;
    padding: 20px 40px;
    box-shadow: 0px 0px 30px -6px #000000;
  }

}
</style>
